<template>
    <router-link
        :to="`moodboard/${post.slug}`"
        class="row no-gutters no-underline zoom-on-hover text-white"
    >
        <div class="col-12 col-lg-7 position-relative">
            <figure class="mb-0">
                <img v-lazy="post.image" alt="" class="img-fluid" />
            </figure>
        </div>
        <div class="col-12 col-lg-5" :style="{ backgroundColor: post.color }">
            <div
                class="px-3 py-4 px-lg-4 d-flex flex-column align-items-center justify-content-between h-100"
            >
                <div class="pl-lg-3 text-center text-lg-left">
                    <h2>{{ post.title }}</h2>
                    <p class="text mt-3 mt-lg-4">
                        {{ post.description }}
                    </p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import Button from "../components/Button.vue";
export default {
    props: {
        post: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    components: {
        Button
    }
};
</script>

<style lang="scss" scoped></style>
