<template>
    <div>
        <div class="moodboard page-wrapper" v-if="dataLoaded">
            <div
                class="d-flex flex-column justify-content-center position-relative"
            >
                <div
                    class="container-longer container-fluid text-white text-center"
                >
                    <div class="row pt-6 pb-5 pt-xl-7 pb-xl-6">
                        <h1 class="col-12">
                            {{ object.acf.title }}
                        </h1>
                        <div
                            class="col-12 text-bigger"
                            v-html="object.acf.description"
                        ></div>
                    </div>
                </div>
            </div>

            <section>
                <div class="container-longer container-fluid">
                    <div class="row no-gutters pb-4 pb-lg-5">
                        <ul class="col-12 list-unstyle">
                            <li
                                v-for="post in object.posts"
                                :key="post.slug"
                                class="mb-4 mb-md-5 mb-xl-6"
                            >
                                <PostItem :post="post" />
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from "axios";
import seoMixin from "@/mixins/seoMixin";
import PostItem from "../components/PostItem.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "moodboard",
    mixins: [seoMixin],
    components: {
        PostItem,
        Footer
    },
    data() {
        return {
            object: {},
            dataLoaded: false
        };
    },
    created() {
        const pageApi = `wp-json/wp/v2/pages/1599?_fields=id,slug,acf,posts`;
        const data = axios
            .get(pageApi)
            .then(res => {
                this.object = res.data;
                // from seoMixin
                this.setSeo(
                    this.object.acf.seo_title,
                    this.object.acf.seo_description,
                    "https://www.wearealive.com/WAA_default.jpeg",
                    "https://www.wearealive.com" + this.$route.fullPath
                );
                this.dataLoaded = true;
                document.dispatchEvent(new Event("custom-render-trigger"));

                setTimeout(() => {
                    $("body").css("visibility", "unset");
                    this.$store.dispatch("setLoading", false);
                }, loadingDelay);
            })
            .catch(e => {
                this.$router.push("/404");
            });
    }
};
</script>

<style lang="scss" scoped></style>
