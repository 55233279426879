export default {
    methods: {
        setSeo(title, description, image, url, is_project_hidden){
            document.title = title;
            document.querySelector('meta[name="description"]').setAttribute("content", description);
            
            if(is_project_hidden) document.querySelector('meta[name="robots"]').setAttribute("content", 'noindex, nofollow');
            else document.querySelector('meta[name="robots"]').setAttribute("content", 'all');
            
            // Linkedin tags
            document.querySelector('meta[property="og:title"]').setAttribute("content", title);
            document.querySelector('meta[property="og:description"]').setAttribute("content", description);
            document.querySelector('meta[property="og:image"]').setAttribute("content", image);
            document.querySelector('meta[property="og:url"]').setAttribute("content", url);
        }
    }
}