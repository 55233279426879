<template>
    <footer
        class="page-footer bg-black text-white py-5 py-xl-7 position-relative"
    >
        <div
            class="
                footer-inner
                container-longer container-fluid
                d-flex
                flex-column flex-lg-row
                justify-content-between
                align-items-center
            "
            v-in-viewport
        >
            <div class="footer-info-box my-4 fade-item">
                <div>
                    <h3 class="uppercase title-3">New project enquires</h3>
                    <a
                        class="title-3 font-weight-light text-white"
                        href="mailto:hello@wearealive.com"
                        >hello@wearealive.com</a
                    >
                </div>
                <div class="mt-5">
                    <h3 class="uppercase title-3">Careers</h3>
                    <a
                        class="title-3 font-weight-light text-white"
                        href="mailto:superstars@wearealive.com"
                        >superstars@wearealive.com</a
                    >
                </div>
            </div>

            <div
                class="
                    footer-logo-box
                    d-flex
                    flex-column
                    align-items-center
                    px-4
                    my-4
                    fade-item
                "
            >
                <img
                    src="@/assets/img/logo-compact.svg"
                    class="logo-img mb-5"
                    alt="We Are Alive"
                />
                <div class="small-text d-flex flex-column align-items-center">
                    <span
                        >Copyright © {{ new Date().getFullYear() }} We Are Alive
                        FZ LLC</span
                    >
                    <span>London UK, Dubai UAE</span>
                </div>
            </div>

            <div class="fade-item social-icons-wrapper">
                <social-icons class="my-4"></social-icons>
            </div>
        </div>
    </footer>
</template>

<script>
export default {};
</script>
